import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  router: any;
  constructor(private route: Router, private auth: AuthService) {
    this.router = route;
  }

  ngOnInit() {
  }

  logOut() {
    console.log('Signing Out..');
    this.auth.signOut();
  }

}
