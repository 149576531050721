import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import 'materialize-css';
import { GMapModule } from 'primeng/primeng';
import { GalleriaModule } from 'primeng/primeng';
import { EditorModule } from 'primeng/editor';
import { CarouselModule } from 'primeng/carousel';
import { MaterializeModule } from 'angular2-materialize';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { AdminGuard } from './core/admin.guard';
// import { AuthService } from './core/auth.service';
import { CoreModule } from './core/core.module';
// import { AdminModule } from './pages/admin/admin.module';

import {
  FirebaseOptionsToken,
  FirebaseAppNameToken,
  FirebaseAppConfigToken
} from 'angularfire2';

// import { HttpModule } from '@angular/http';

import { AppComponent } from './app.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { ProjectsPageComponent } from './pages/projects-page/projects-page.component';
import { ProjectDetailsPageComponent } from './pages/project-details-page/project-details-page.component';
import { EmploymentFormPageComponent } from './pages/employment-form-page/employment-form-page.component';
import { CareersPageComponent } from './pages/careers-page/careers-page.component';
import { EducationComponent } from './pages/shared/education/education.component';
import { ExperienceComponent } from './pages/shared/experience/experience.component';
import { environment } from '../environments/environment.prod';
import { DropZoneDirective } from './drop-zone.directive';
import { FileUploadComponent } from './pages/shared/file-upload/file-upload.component';
import { AdminComponent } from './pages/admin/admin.component';
import { AdminLoginComponent } from './pages/admin/admin-login/admin-login.component';
import { AdminProjectsComponent } from './pages/admin/admin-projects/admin-projects.component';
import { AdminJobsComponent } from './pages/admin/admin-jobs/admin-jobs.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { SafetyPageComponent } from './pages/safety-page/safety-page.component';
import { AdminSettingsComponent } from './pages/admin/admin-settings/admin-settings.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { AdminUpdatesComponent } from './pages/admin/admin-updates/admin-updates.component';
import { UpdatesPageComponent } from './pages/updates-page/updates-page.component';
import { DownloadsPageComponent } from './pages/downloads-page/downloads-page.component';
import { PeoplePageComponent } from './pages/people-page/people-page.component';
import { TeamPageComponent } from './pages/about-page/team-page/team-page.component';
import { PartnersPageComponent } from './pages/about-page/partners-page/partners-page.component';
import { VisionPageComponent } from './pages/about-page/vision-page/vision-page.component';

const appRoutes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomePageComponent },

  { path: 'projects', component: ProjectsPageComponent },
  { path: 'projects/:id', component: ProjectDetailsPageComponent },

  { path: 'updates/:id', component: UpdatesPageComponent },

  { path: 'downloads', component: DownloadsPageComponent },

  { path: 'careers', component: CareersPageComponent },
  { path: 'careers/:id', component: EmploymentFormPageComponent },

  { path: 'contact', component: ContactPageComponent },

  { path: 'team', component: PeoplePageComponent },

  { path: 'safety', component: SafetyPageComponent },

  { path: 'about', component: AboutPageComponent },
  {
    path: 'about',
    component: AboutPageComponent,
    children: [
      {path: '', redirectTo: 'team',  pathMatch: 'full'},
      {path: 'team', component: TeamPageComponent},
      {path: 'partners', component: PartnersPageComponent},
      {path: 'vision', component: VisionPageComponent}
    ]
  },

  {
    path: 'admin',
    component: AdminComponent,
    children: [
      {path: '', redirectTo: 'login',  pathMatch: 'full'},
      {path: 'login', component: AdminLoginComponent},
      {path: 'jobs', component: AdminJobsComponent, canActivate: [AdminGuard]},
      {path: 'projects', component: AdminProjectsComponent, canActivate: [AdminGuard]},
      {path: 'global', component: AdminSettingsComponent, canActivate: [AdminGuard]},
      {path: 'updates', component: AdminUpdatesComponent, canActivate: [AdminGuard]}
    ]
  },

  { path: '**', redirectTo: 'home' }
];

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    ProjectsPageComponent,
    ProjectDetailsPageComponent,
    EmploymentFormPageComponent,
    CareersPageComponent,
    EducationComponent,
    ExperienceComponent,
    DropZoneDirective,
    FileUploadComponent,
    AdminComponent,
    AdminLoginComponent,
    AdminProjectsComponent,
    AdminJobsComponent,
    AdminUpdatesComponent,
    ContactPageComponent,
    SafetyPageComponent,
    AdminSettingsComponent,
    AboutPageComponent,
    UpdatesPageComponent,
    DownloadsPageComponent,
    PeoplePageComponent,
    TeamPageComponent,
    PartnersPageComponent,
    VisionPageComponent
  ],
  imports: [
    BrowserModule,
    MaterializeModule,
    GMapModule,
    GalleriaModule,
    EditorModule,
    CarouselModule,
    RouterModule.forRoot(
      appRoutes
      // { enableTracing: true } // <-- debugging purposes only
    ),
    AngularFireModule,
    // .initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CoreModule,
    // HttpModule
  ],
  providers: [
    { provide: FirebaseOptionsToken, useValue: environment.firebase },
    { provide: FirebaseAppNameToken, useValue: '[DEFAULT]' },
    { provide: FirebaseAppConfigToken, useValue: undefined }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
