import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {
  images: any;
  constructor() {
    this.images = [
      './assets/images/hpi4.jpg',
      './assets/images/hpi1.jpg',
      './assets/images/hpi2.jpg',
      './assets/images/hpi3.jpg'
    ];
  }

  ngOnInit() {
  }

}
