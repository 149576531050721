import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import { GlobalsService } from '../../core/globals.service';
import { MaterializeDirective, MaterializeAction } from 'angular2-materialize';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.css']
})

export class ContactPageComponent implements OnInit {
  globalActions = new EventEmitter<string|MaterializeAction>();
  toastParams: any;
  constructor(private afs: AngularFirestore, private router: Router, private gs: GlobalsService
  ) { }

  ngOnInit() {
  }

  submitForm(form) {
    form.value['email_to'] = this.gs.globalSettings.email;
    Object.keys(form.value).forEach(key => {
      if (form.value[key] === undefined) {
        form.value[key] = 'None';
      }
    });
    const contactCollections = this.afs.collection('contactus');
    contactCollections.add(form.value).then(ref => {
      // console.log('Contact Form Submitted');
      this.toastParams = ['Your Information has been Submitted. We will contact you as soon as possible.', 4000];
      this.triggerToast();
      setTimeout(() => {
        this.router.navigateByUrl('/home');
      }, 4500);
    }).catch(err => {
      this.toastParams = ['Error. Please Try Again or Contact Admin.', 4000];
      this.triggerToast();
    });
  }

  triggerToast() {
    this.globalActions.emit('toast');
  }

}
