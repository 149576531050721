import { Component, OnInit, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MaterializeAction } from 'angular2-materialize';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Project } from '../projects-page/project';
// import { GalleriaModule } from 'primeng/galleria';

declare var $: any;

@Component({
  selector: 'app-project-details-page',
  templateUrl: './project-details-page.component.html',
  styleUrls: ['./project-details-page.component.css']
})
export class ProjectDetailsPageComponent implements OnInit, AfterViewInit {
  projectDocument: AngularFirestoreDocument<Project>;
  project: any;
  floors: any = [];
  images: any = [];
  category: any;
  id: any;
  // @ViewChild('myCarousel') carouselElement;
  // actions = new EventEmitter<string|MaterializeAction>();
  showInitialized = false;

  constructor(private afs: AngularFirestore, private route: ActivatedRoute, private router: Router) {
    // this.project = {
    //   id: '1',
    //   name: 'Al-Gharafa Shopping Center',
    //   category: 'commercial',
    //   floors: 'B+G+M+2F',
    //   // tslint:disable-next-line:max-line-length
    //   desc: 'Together with DIWAN AL AMIRI design house, SEC undertook the development of Doha’s newest (at the time) shopping hub at Gharafa. Centrally located and accessible to all, the center comprises a basement, ground floor, mezzanine and two administrative floors. Its extensive shopping facilities and office space make it the destination of thousands of visitors and shoppers all year round.',
    //   images: [
    //     // '../../../assets/images/algharafashop_1.png',
    //     '../../../assets/images/algharafashop_2.jpg',
    //     '../../../assets/images/algharafashop_3.jpg'
    //   ],
    //   default_image: '../../../assets/images/algharafashop_1.png'
    // };
    // this.floors = this.project.floors.split('+');
    this.route.params.subscribe( params => {
      this.id = params.id;
      // console.log(params);
    });
    this.projectDocument = this.afs.doc('projects/' + this.id);
    this.projectDocument.valueChanges().subscribe( data => {
      this.project = data;
      this.project.images.map(image => {
        this.images.push({
          source: image,
          title: '',
          alt: ''
        });
      });
      // console.log(this.images);
      this.floors = data.floors.split('+');
      if (data.category === 'commercial') {
        this.category = 'commercial building';
      } else if (data.category === 'office') {
        this.category = 'office building';
      } else if (data.category === 'hospitality') {
        this.category = 'hospitality building';
      } else if (data.category === 'res_indi') {
        this.category = 'individual villas';
      } else if (data.category === 'res_apts') {
        this.category = 'residential apartments & units';
      } else {
        this.category = 'residential compounds & villas';
      }
    });
  }

  ngOnInit() {
    this.router.events.subscribe((e) => {
        window.scrollTo(0, 0);
    });
  }

  ngAfterViewInit() {
    // $( '.carousel' ).carousel({fullWidth: true});
    // window.setTimeout(() => {
    //   this.carouselElement.nativeElement.classList.toggle('initialized');
    //   this.actions.emit('carousel');
    // }, 1000);
  }

}
