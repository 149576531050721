import { Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { GMapModule } from 'primeng/gmap';
import { GlobalsService } from './core/globals.service';

import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { ArrayType } from '@angular/compiler/src/output/output_ast';
import { identifierModuleUrl } from '../../node_modules/@angular/compiler';

declare var google: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('updateToggle', [
      state('inactive', style({
        transform: 'translateY(15px)',
        opacity: '0',
        display: 'none'
      })),
      state('active',   style({
        transform: 'translateY(0px)',
        opacity: '1',
      })),
      transition('inactive => active', animate('250ms cubic-bezier(0.165, 0.84, 0.44, 1)')),
      transition('active => inactive', animate('200ms cubic-bezier(0.165, 0.84, 0.44, 1)'))
    ]),
    trigger('loaderToggle', [
      state('inactive', style({
        opacity: '0',
        display: 'none'
      })),
      state('active',   style({
        opacity: '1',
      })),
      transition('inactive => active', animate('1.5s cubic-bezier(0.55, 0.055, 0.675, 0.19)')),
      transition('active => inactive', animate('1.5s cubic-bezier(0.19, 1, 0.22, 1)'))
    ])
  ],
  encapsulation: ViewEncapsulation.None,
})

export class AppComponent implements OnInit {
  title = 'app';
  menuItems: any;
  sidenavActions: EventEmitter<any>;
  sidenavParams: any[];
  router: any;
  options: any;
  overlays: any = [];
  showUpdate: any = 'inactive';
  showLoader: any = 'active';
  mapHeight: any;

  constructor(private route: Router, private gs: GlobalsService) {
    this.sidenavActions = new EventEmitter<any>();
    this.sidenavParams = [];
    this.router = route;
    this.menuItems = [
          { name: 'Home', route: '/home' },
          { name: 'About Us',
            sublinks: [
            {
              name: 'Message From CEO',
              route: 'about/team'
            },
            {
              name: 'Partners',
              route: 'about/partners'
            },
            {
              name: 'Mission, Vision & Values',
              route: 'about/vision'
            }]
          },
          { name: 'Projects', route: '/projects' },
          { name: 'Safety, Health & Environment', route: '/safety' },
          { name: 'Meet The Team', route: '/team' },
          { name: 'Join SEC', route: '/careers' },
          { name: 'Contact', route: '/contact' }
      ];
    this.showUpdate = window.innerWidth > 768 ? 'active' : 'inactive';
    if (window.innerWidth > 1300) {
      this.mapHeight = '450px';
    } else if (window.innerWidth > 425 && window.innerWidth < 1300) {
      this.mapHeight = '335px';
    } else {
      this.mapHeight = '150px';
    }
  }

  ngOnInit() {
      this.options = {
          center: {lat: 25.3180858, lng: 51.52302},
          zoom: 16
      };
      const marker = new google.maps.Marker({
        position: {
            lat: 25.3180858,
            lng: 51.52302
        },
        title: ''
      });
      this.overlays.push(marker);

      setTimeout(() => {
        this.showLoader = 'inactive';
      }, 2000);
  }

  close() {
    this.sidenavActions.emit({ action: 'sideNav', params: ['hide'] });
  }

  showUpdateDiv() {
    this.showUpdate = this.showUpdate === 'active' ? 'inactive' : 'active';
  }
}

// export interface MenuItem {
//   name: string;
//   route: string;
//   sublinks: Array;
// }
