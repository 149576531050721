import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-downloads-page',
  templateUrl: './downloads-page.component.html',
  styleUrls: ['./downloads-page.component.css']
})
export class DownloadsPageComponent implements OnInit {

  downloads = [
    {
      title: 'BS EN ISO 14001-2015',
      url: 'https://firebasestorage.googleapis.com/v0/b/shannon-868b9.appspot.com/o/uploads%2FBS%20EN%20ISO%2014001-2015.pdf?alt=media&token=7aa2d16e-baca-4c86-9a5b-49fc04446826'
    },
    {
      title: 'BS EN ISO 9001-2015',
      url: 'https://firebasestorage.googleapis.com/v0/b/shannon-868b9.appspot.com/o/uploads%2FBS%20EN%20ISO%209001-2015.pdf?alt=media&token=308cd582-9cda-4128-88d9-e5d1fdaa7b28'
    },
    {
      title: 'OHAS 18001-2007',
      url: 'https://firebasestorage.googleapis.com/v0/b/shannon-868b9.appspot.com/o/uploads%2FOHAS%2018001-2007.pdf?alt=media&token=571b9cb6-bbe0-48c4-bcdb-a64218a5a456'
    },
    {
      title: 'Classification Certificate 2020',
      url: 'https://firebasestorage.googleapis.com/v0/b/shannon-868b9.appspot.com/o/uploads%2FCLASSIFICATION%20CERTIFICATE%202020.pdf?alt=media&token=5b3b0d97-17aa-44f0-b5da-965b9d0831a6'
    }
  ];

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((e) => {
        window.scrollTo(0, 0);
    });
  }

}
