export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyD52Ve7smmwNYbe4SfpF6sb8y_JSY6STdk',
    authDomain: 'shannon-868b9.firebaseapp.com',
    databaseURL: 'https://shannon-868b9.firebaseio.com',
    projectId: 'shannon-868b9',
    storageBucket: 'shannon-868b9.appspot.com',
    messagingSenderId: '450929190028'
  }
};
