import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Project } from './project';

import { GlobalsService } from '../../core/globals.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-projects-page',
  templateUrl: './projects-page.component.html',
  styleUrls: ['./projects-page.component.css']
})

export class ProjectsPageComponent implements OnInit {
  projectsCollection: AngularFirestoreCollection<Project>;
  projects: any;
  // Observable<Project[]>;

  commercial: any;
  office: any;
  hospitality: any;
  res_apts: any;
  res_indi: any;
  res_cpds: any;
  cardHor = false;

  constructor(private afs: AngularFirestore, private gs: GlobalsService, private router: Router) {
  }

  ngOnInit() {
    this.router.events.subscribe((e) => {
        window.scrollTo(0, 0);
    });

    // if (window.innerWidth > 1152) {
    //   this.cardHor = true;
    // } else {
    //   this.cardHor = false;
    // }

    this.projectsCollection = this.afs.collection('projects');
    this.projectsCollection.valueChanges().subscribe( data => {
      this.projects = data;
      const grouped = this.groupBy(this.projects, project => project.category);
      this.commercial = grouped.get('commercial');
      this.office = grouped.get('office');
      this.hospitality = grouped.get('hospitality');
      this.res_apts = grouped.get('res_apts');
      this.res_cpds = grouped.get('res_cpds');
      this.res_indi = grouped.get('res_indi');
    });
  }

  // Vanilla Group By
  groupBy(list, keyGetter) {
    const mapper = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        if (!mapper.has(key)) {
          mapper.set(key, [item]);
        } else {
          mapper.get(key).push(item);
        }
    });
    return mapper;
  }

}
