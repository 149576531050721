import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-safety-page',
  templateUrl: './safety-page.component.html',
  styleUrls: ['./safety-page.component.css']
})
export class SafetyPageComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((e) => {
        window.scrollTo(0, 0);
    });
  }

}
