import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.css']
})
export class ExperienceComponent implements OnInit {
  @Input() index: any;
  @Output() formValue = new EventEmitter();
  experienceForm: FormGroup;
  maxDate = new Date().toISOString().split('T')[0];

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
    this.experienceForm = this.fb.group({
      emp_name: '',
      from: '',
      to: '',
      last_sal: '',
      phone: '',
      last_job_title: '',
      reason: '',
      exp_addr: '',
      index: this.index
    });
    this.onChanges();
  }

  onChanges(): void {
    this.experienceForm.valueChanges.subscribe(val => {
      // console.log(this.educationForm.value);
      if (this.experienceForm.valid) {
        this.formValue.emit(this.experienceForm.value);
      }
    });
  }
}
