import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-people-page',
  templateUrl: './people-page.component.html',
  styleUrls: ['./people-page.component.css']
})
export class PeoplePageComponent implements OnInit {

  people = [
    {
      name: 'Ahmad Mohd Al-Khori',
      position: 'Board of Directors'
    },
    {
      name: 'Eng. Hany Abdel Fattah',
      position: 'Board of Directors'
    },
    {
      name: 'Mohamed Ghadban',
      position: 'Board of Directors'
    },
    {
      name: 'Eng. Hany Abdel Fattah',
      email: 'hany@shannoneng.com',
      position: 'Chief Executive Officer',
    },
    {
      name: 'Eng. Ahmed Shehab',
      email: 'a.shehab@shannoneng.com',
      position: 'Business Development Manager',
    },
    {
      name: 'Eng. Dushyantha Cooray',
      email: 'd.cooray@shannoneng.com',
      position: 'Tender Department Manager',
    },
    // {
    //   name: 'Eng. Tarek El Akrat',
    //   email: 'tarek.akrat@shannoneng.com',
    //   position: 'Projects Manager',
    // },
    {
      name: 'Eng. Amr Aziz',
      email: 'a.aziz@shannoneng.com',
      position: 'Projects Manager',
    },
    // {
    //   name: 'Eng. Moataz Nazmy',
    //   email: 'm.nazmy@shannoneng.com',
    //   position: 'Projects Manager',
    // },
    // {
    //   name: 'Eng. Nasser Elsayed',
    //   email: 'n.abdlaal@shannoneng.com',
    //   position: 'Planning Department Manager',
    // },
    // {
    //   name: 'Eng. Waleed Talaat',
    //   email: 'w.talaat@shannoneng.com',
    //   position: 'Contracts Manager',
    // },
    {
      name: 'Eng. Badawi Gaber',
      email: 'badawi@shannoneng.com',
      position: 'Technical Office Manager',
    },
    {
      name: 'Santhosh Kumar',
      email: 'santhosh@shannoneng.com',
      position: 'Accounts Department Manager',
    },
    {
      name: 'Darine El Kurdi',
      email: 'darine@shannoneng.com',
      position: 'Administration & Finance Manager',
    }
  ];
  ceo: any;
  bdm: any;
  tdm: any;
  pm: any;
  cm: any;
  tt: any;
  // pdm: any;
  adm: any;
  admin: any;
  bod: any;

  constructor() {
  }

  ngOnInit() {
    const grouped = this.groupBy(this.people, p => p.position);
    this.ceo = grouped.get('Chief Executive Officer');
    this.bdm = grouped.get('Business Development Manager');
    this.tdm = grouped.get('Tender Department Manager');
    this.pm = grouped.get('Projects Manager');
    this.cm = grouped.get('Contracts Manager');
    this.tt = grouped.get('Technical Office Manager');
    // this.pdm = grouped.get('Planning Department Manager');
    this.admin = grouped.get('Administration & Finance Manager');
    this.adm = grouped.get('Accounts Department Manager');
    this.bod = grouped.get('Board of Directors');
  }

  toggle(id, e) {
    // tslint:disable-next-line:no-unused-expression
    let elem = document.getElementById(id).style.display;
    if (elem === 'flex') {
      elem = 'none';
      e.target.style.borderBottom = '0px';
    } else {
      elem = 'flex';
      e.target.style.borderBottom = '1px solid var(--red)';
    }
    document.getElementById(id).style.display = document.getElementById(id).style.display === 'flex' ? 'none' : 'flex';
  }

  showPeople(id, e) {
    document.getElementById(id).style.display = 'flex';
    e.target.style.borderBottom = '1px solid var(--red)';
  }

  groupBy(list, keyGetter) {
    const mapper = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        if (!mapper.has(key)) {
          mapper.set(key, [item]);
        } else {
          mapper.get(key).push(item);
        }
    });
    return mapper;
  }

}
