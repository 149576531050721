import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from 'angularfire2/storage';
import { Observable } from 'rxjs';
import { MaterializeDirective, MaterializeAction } from 'angular2-materialize';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURLs: any = [];
  isHovering: boolean;
  allowedExtensions: any = [];
  // fileURLs = [];
  isUploaded = false;
  notSupported = false;
  isCanceled = false;
  @Input() imageOnly: boolean;
  @Output() files = new EventEmitter();

  globalActions = new EventEmitter<string|MaterializeAction>();
  toastParams: any;

  constructor(private storage: AngularFireStorage) {
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  ngOnInit() {
    if (this.imageOnly) {
      this.allowedExtensions = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG'];
    } else {
      this.allowedExtensions = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'doc', 'docx', 'pdf'];
    }
  }

  startUpload(event: FileList) {
    this.isUploaded = false;
    this.notSupported = false;
    this.isCanceled = false;
    Array.from(event).forEach( (file) => {
      const f = file.name.split('.');
      if (!this.allowedExtensions.includes(f[f.length - 1])) {
        this.toastParams = ['Unsupported File Extension.', 4000];
        this.triggerToast();
        this.notSupported = true;
        return;
      }
      const path = `uploads/${new Date().getTime()}_${file.name}`;
      this.task = this.storage.upload(path, file);
      this.percentage = this.task.percentageChanges();
      this.snapshot = this.task.snapshotChanges();
      this.task.then(complete => complete.ref.getDownloadURL().then(data => {
        this.downloadURLs.push(data);
        this.isUploaded = true;
        this.files.emit(this.downloadURLs);
        this.toastParams = ['File Added.', 4000];
        this.triggerToast();
      })).catch(err => {
        console.error(err);
        this.toastParams = ['Error. Please Try Again / Contact Admin.', 4000];
        this.triggerToast();
      });
      // this.fileURLs.push(path);
    });
  }

  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

  triggerToast() {
    this.globalActions.emit('toast');
  }

}
