import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import * as firebase from 'firebase';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';

import { Observable, pipe, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { User } from './user';
import { MaterializeAction } from 'angular2-materialize';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$: Observable<User>;
  globalActions = new EventEmitter<string|MaterializeAction>();
  toastParams: any;

  constructor(private afAuth: AngularFireAuth,
              private afs: AngularFirestore,
              private router: Router) {
      //// Get auth data, then get firestore user document || null
      this.user$ = this.afAuth.authState
      .pipe(
        switchMap(user => {
          if (user) {
            return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
          } else {
            return of(null);
          }
        })
      );
  }

  ///// Login/Signup //////

  googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.oAuthLogin(provider);
  }

  private oAuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
      .then((credential) => {
        // this.updateUserData(credential.user);
        this.userExists(credential.user.uid).then(response => {
          if (response.exists) {
            this.user$.subscribe( val => {
              if (this.canCRUD(val)) {
                this.router.navigateByUrl('admin/projects');
              } else {
                this.toastParams = ['Access Restricted - Only for Admins!', 4000];
                this.triggerToast();
                console.error('Access Restricted - Only for Admins!');
              }
            });
          } else {
            this.toastParams = ['Access Restricted - Only for Admins!', 4000];
            this.triggerToast();
            console.error('Access Restricted - Only for Admins!');
          }
        }).catch(err => {
          this.toastParams = ['Missing or Insufficient Permissions.', 4000];
          this.triggerToast();
          console.error(err);
        });
      });
  }

  newLogin() {
    return this.adminLogin();
  }

  private adminLogin() {
    return this.afAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((credential) => {
        this.afs.doc<User>(`users/${credential.user.uid}`).set({
          uid: credential.user.uid,
          email: credential.user.email,
          roles: {
            admin: false
          }
        }, {merge: true})
        .then(() => {
          this.toastParams = [`User ${credential.user.email} successfully added! Awaiting admin access.`, 4000];
          this.triggerToast();
          console.log(`User ${credential.user.email} successfully added! Awaiting admin access.`);
        })
        .catch((err) => {
          console.error('Cannot Add User - ', err);
          this.toastParams = ['Cannot Add User', 4000];
          this.triggerToast();
        });
      });
  }

  signOut() {
    this.afAuth.auth.signOut().then(() => {
      this.router.navigateByUrl('/home');
    });
  }

  userExists(uid) {
    return this.afs.doc(`users/${uid}`).ref.get();
  }

  // private updateAddAdmin(user) {
  //   // Sets user data to firestore on login
  //   const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
  //   const data: User = {
  //     uid: user.uid,
  //     email: user.email,
  //     roles: {
  //       admin: true
  //     }
  //   };
  //   return userRef.set(data, { merge: true });
  // }

  ///// Role-based Authorization //////
  canCRUD(user: User): boolean {
    const allowed = ['admin'];
    return this.checkAuthorization(user, allowed);
  }

  // determines if user has matching role
  private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    // if (!user) {
    //   return false;
    // }
    for (const role of allowedRoles) {
      if ( user.roles[role] ) {
        return true;
      }
    }
    return false;
  }

  triggerToast() {
    this.globalActions.emit('toast');
  }

}
