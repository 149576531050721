import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Job } from './job';
import { GlobalsService } from '../../core/globals.service';


declare var $: any;

@Component({
  selector: 'app-careers-page',
  templateUrl: './careers-page.component.html',
  styleUrls: ['./careers-page.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class CareersPageComponent implements OnInit, AfterViewInit {

  jobsCollection: AngularFirestoreCollection<Job>;
  jobs: any;
  noJobs: any;

  constructor(private afs: AngularFirestore, private gs: GlobalsService) {
    // this.vacs = [
    //   {
    //     id: '12334',
    //     position: 'Senior Software Engineer',
    //     isOpen: true,
    //     desc: "<p>Do you get a kick out of watching software unfold before your eyes? Do you dream about code every night? If so, we'd love to talk to you about a new product we're developing in the social media space. We're looking for a top-notch Software Engineer who always sweats the small stuff and cares about impeccable code. If you see glitches as an enjoyable challenge rather than a hardship and if you are willing to put in long hours to see a project come to fruition, get in touch with us today.</p><ul><li>Requirement One</li><li>Bachelors Degree</li></ul>"
    //   },
    //   {
    //     id: '21235',
    //     position: 'Jr. Software Developer',
    //     isOpen: true,
    //     desc: "Do you get a kick out of watching software unfold before your eyes? Do you dream about code every night? If so, we'd love to talk to you about a new product we're developing in the social media space. We're looking for a top-notch Software Engineer who always sweats the small stuff and cares about impeccable code. If you see glitches as an enjoyable challenge rather than a hardship and if you are willing to put in long hours to see a project come to fruition, get in touch with us today."
    //   },
    //   {
    //     id: '33345',
    //     position: 'Architect',
    //     isOpen: true,
    //     desc: "Design, Plan and Develop. Designing, planning and developing are integral tasks in an architect's daily routine. Architects may be required to provide predesign figures such as an environmental impact or feasibility study, cost analysis and land-use study."
    //   }
    // ];
  }

  ngOnInit() {
    this.jobsCollection = this.afs.collection('jobs');
    this.jobsCollection.valueChanges().subscribe(data => {
      this.jobs = data;
      if (data.length === 0) {
        this.noJobs = true;
      } else {
        this.noJobs = false;
      }
    });
  }

  ngAfterViewInit() {
    $( '.collapsible' ).collapsible();
  }

}
