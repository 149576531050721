import { Component, OnInit, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MaterializeAction } from 'angular2-materialize';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Update } from './updates';

@Component({
  selector: 'app-updates-page',
  templateUrl: './updates-page.component.html',
  styleUrls: ['./updates-page.component.css']
})
export class UpdatesPageComponent implements OnInit {
  updateDocument: AngularFirestoreDocument<Update>;
  update: any;
  images: any = [];
  id: any;
  showInitialized = false;

  constructor(private afs: AngularFirestore, private route: ActivatedRoute) {
    this.route.params.subscribe( params => {
      this.id = params.id;
    });
    this.updateDocument = this.afs.doc('updates/' + this.id);
    this.updateDocument.valueChanges().subscribe( data => {
      this.update = data;
      this.update.images.map(image => {
        this.images.push({
          source: image,
          title: '',
          alt: ''
        });
      });
      // console.log(this.images);
    });
  }

  ngOnInit() {
  }

}
