import { Component, OnInit, EventEmitter, ViewEncapsulation } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Project } from '../../projects-page/project';
import { MaterializeDirective, MaterializeAction } from 'angular2-materialize';
// import { EditorModule } from 'primeng/editor';
import { Job } from '../../careers-page/job';

@Component({
  selector: 'app-admin-jobs',
  templateUrl: './admin-jobs.component.html',
  styleUrls: ['./admin-jobs.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminJobsComponent implements OnInit {
  jobsCollection: AngularFirestoreCollection<Job>;
  jobs: any;
  modalActions = new EventEmitter<string|MaterializeAction>();
  // commercial: any;
  // office: any;
  // hospitality: any;
  // res_apts: any;
  // res_indi: any;
  // res_cpds: any;
  globalActions = new EventEmitter<string|MaterializeAction>();
  toastParams: any;
  noJobs: any;

  constructor(private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.jobsCollection = this.afs.collection('jobs');
    this.jobsCollection.valueChanges().subscribe((data) => {
      this.jobs = data;
      if (data.length === 0) {
        this.noJobs = true;
      } else {
        this.noJobs = false;
      }
    });
  }

  openModal() {
    this.modalActions.emit({action: 'modal', params: ['open']});
  }
  closeModal() {
    this.modalActions.emit({action: 'modal', params: ['close']});
  }

  addJob(form) {
    if (form.value.isOpen === 'y') {
      form.value.isOpen = true;
    } else {
      form.value.isOpen = true;
    }
    // console.log(form.value);
    this.jobsCollection.add(form.value).then(ref => {
      this.afs.doc('/jobs/' + ref.id).set({
        id: ref.id
      }, { merge: true });
      this.closeModal();
      this.toastParams = ['Job Added.', 4000];
      this.triggerToast();
    }).catch(err => {
      this.toastParams = ['Error. Please Try Again / Contact Admin.', 4000];
      this.triggerToast();
      console.error(err);
    });
  }

  deleteJob(id) {
    this.jobsCollection.doc(id).delete().then(() => {
      // console.log('Document successfully deleted!');
      this.toastParams = ['Job Deleted.', 4000];
      this.triggerToast();
    }).catch((error) => {
        console.error('Error removing document: ', error);
    });
  }

  triggerToast() {
    this.globalActions.emit('toast');
  }

  toggleVacancy(job) {
    this.jobsCollection.doc(job.id).update({
      isOpen: !job.isOpen
    }).then(() => {
      // console.log('Document successfully deleted!');
      this.toastParams = ['Job Updated.', 4000];
      this.triggerToast();
    }).catch((error) => {
        console.error('Error updating document: ', error);
    });
  }

}
