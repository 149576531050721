import { Component, OnInit, EventEmitter } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Project } from '../../projects-page/project';
import { MaterializeDirective, MaterializeAction } from 'angular2-materialize';

@Component({
  selector: 'app-admin-projects',
  templateUrl: './admin-projects.component.html',
  styleUrls: ['./admin-projects.component.css']
})
export class AdminProjectsComponent implements OnInit {
  projectsCollection: AngularFirestoreCollection<Project>;
  projects: any;
  modalActions = new EventEmitter<string|MaterializeAction>();
  selectOptions: any[];
  allImages: any[];
  globalActions = new EventEmitter<string|MaterializeAction>();
  toastParams: any;
  noProjects: any;
  // commercial: any;
  // office: any;
  // hospitality: any;
  // res_apts: any;
  // res_indi: any;
  // res_cpds: any;

  constructor(private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.projectsCollection = this.afs.collection('projects');
    this.projectsCollection.valueChanges().subscribe((data) => {
      this.projects = data;
      if (data.length === 0) {
        this.noProjects = true;
      } else {
        this.noProjects = false;
      }
    });
    this.selectOptions = [
      {value: 1, name: 'Commercial'},
      {value: 2, name: 'Office'},
      {value: 3, name: 'Hospitality'},
      {value: 4, name: 'Residential Apartments'},
      {value: 5, name: 'Residential Individual'},
      {value: 6, name: 'Residential Compounds'}
    ];
  }

  openModal() {
    this.modalActions.emit({action: 'modal', params: ['open']});
  }
  closeModal() {
    this.modalActions.emit({action: 'modal', params: ['close']});
  }

  getFiles(files) {
    this.allImages = files;
    // console.log(this.allImages);
  }

  addProject(form) {
    form.value.is_ongoing = form.value.is_ongoing === 'true' ? true : false;
    let category = form.value.category;
    // console.log(category);
    if (category == 1) {
      category = 'commercial';
      form.value['category'] = category;
    } else if (category == 2) {
      category = 'office';
      form.value['category'] = category;
    } else if (category == 3) {
      category = 'hospitality';
      form.value['category'] = category;
    } else if (category == 4) {
      category = 'res_apts';
      form.value['category'] = category;
    } else if (category == 5) {
      category = 'res_indi';
      form.value['category'] = category;
    } else if (category == 6) {
      category = 'res_cpds';
      form.value['category'] = category;
    }
    const i = this.allImages[form.value['main_img']];
    this.allImages.splice(form.value['main_img'], 1);
    form.value['main_img'] = i;
    form.value['images'] = this.allImages;
    // console.log(form.value);
    this.projectsCollection.add(form.value).then(ref => {
      this.afs.doc('/projects/' + ref.id).set({
        id: ref.id
      }, { merge: true });
      this.closeModal();
      this.toastParams = ['Project Added.', 4000];
      this.triggerToast();
    }).catch(err => {
      this.toastParams = ['Error. Please Try Again / Contact Admin.', 4000];
      this.triggerToast();
      console.error(err);
    });
  }

  deleteProject(id) {
    this.projectsCollection.doc(id).delete().then(() => {
      // console.log('Document successfully deleted!');
      this.toastParams = ['Project Deleted.', 4000];
      this.triggerToast();
    }).catch((error) => {
        console.error('Error removing document: ', error);
    });
  }

  triggerToast() {
    this.globalActions.emit('toast');
  }

}
