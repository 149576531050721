import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.css']
})
export class EducationComponent implements OnInit {
  @Input() index: any;
  @Output() formValue = new EventEmitter();
  educationForm: FormGroup;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit() {
    this.educationForm = this.fb.group({
      school_type: '',
      inst_name: '',
      loc: '',
      year: '',
      major: '',
      index: this.index
    });
    this.onChanges();
  }

  onChanges(): void {
    this.educationForm.valueChanges.subscribe(val => {
      // console.log(this.educationForm.value);
      this.formValue.emit(this.educationForm.value);
    });
  }

}
