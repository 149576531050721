import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Update } from '../pages/updates-page/updates';
import { collectExternalReferences } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  updatesCollection: AngularFirestoreCollection<Update>;
  updates: any;

  globalSettings: any;
  commercial_img: any;
  office_img: any;
  hospitality_img: any;
  res_apts_img: any;
  res_indi_img: any;
  res_cpds_img: any;
  id = 'vU1Pro9ZR6VYR018oFTp';

  constructor(private afs: AngularFirestore) {
    this.globalSettings = this.afs.doc(`global/${this.id}`).ref.get().then(doc => {
      this.globalSettings = doc.data();
      this.commercial_img = this.globalSettings.commercial;
      this.office_img = this.globalSettings.office;
      this.hospitality_img = this.globalSettings.hospitality;
      this.res_apts_img = this.globalSettings.res_apts;
      this.res_cpds_img = this.globalSettings.res_cpds;
      this.res_indi_img = this.globalSettings.res_indi;
      // function(querySnapshot) {
      // querySnapshot.forEach(function(doc) {
      //     // doc.data() is never undefined for query doc snapshots
      //     console.log(doc.id, ' => ', doc.data());
    });

    this.updatesCollection = this.afs.collection('updates');
    this.updatesCollection.valueChanges().subscribe( data => {
      this.updates = data;
    });
  }
}
