import { Component, OnInit, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Job } from '../careers-page/job';
import { MaterializeDirective, MaterializeAction } from 'angular2-materialize';
import { GlobalsService } from '../../core/globals.service';

@Component({
  selector: 'app-employment-form-page',
  templateUrl: './employment-form-page.component.html',
  styleUrls: ['./employment-form-page.component.css']
})
export class EmploymentFormPageComponent implements OnInit {
  job: any;
  jobDocument: any;
  id: any;
  minDate = new Date().toISOString().split('T')[0];
  e = 1;
  educations = [1];
  educationForms: any = [];

  exp = 1;
  experiences = [1];
  experienceForms: any = [];

  private tabSelectionParams = null;
  isWorkDisabled = true;
  isCompleteDisabled = true;
  allFiles = [];
  filledForm: any;
  toastParams: any;
  // birthDate: string;
  // birthDateActions = new EventEmitter<string|MaterializeAction>();
  globalActions = new EventEmitter<string|MaterializeAction>();

  @ViewChild('personalForm') pf;
  @ViewChild('workForm') wf;
  @ViewChild('completeForm') cf;

  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router,
    private gs: GlobalsService
  ) {
    this.route.params.subscribe( params => {
      this.id = params.id;
    });
   }

  ngOnInit() {
    if (this.id !== 'apply') {
      this.jobDocument = this.afs.doc('jobs/' + this.id);
        this.jobDocument.valueChanges().subscribe( data => {
        this.job = data;
      });
    } else {
      this.job = {
        id: '',
        position: '',
        description: ''
      };
    }
  }

  // openDatePicker() {
  //     // actions are open or close
  //     this.birthDateActions.emit({action: 'pickadate', params: ['open']});
  // }

  incrementEducation() {
    this.e++;
    this.educations.push(this.e);
  }
  incrementExperience() {
    if (this.experiences.length < 3) {
      this.exp++;
      this.experiences.push(this.exp);
    }
  }

  deleteEducation(index) {
    if (this.educations.length > 1) {
      const i = this.educations.indexOf(index);
      if (i > -1) {
        this.educations.splice(i, 1);
        this.educationForms.splice(i, 1);
      }
    }
  }
  deleteExperience(index) {
    if (this.experiences.length > 1) {
      const i = this.experiences.indexOf(index);
      if (i > -1) {
        this.experiences.splice(i, 1);
        this.experienceForms.splice(i, 1);
      }
    }
  }

  educationEvent(form) {
    const i = this.educations.indexOf(form.index);
    if (i !== -1) {
      this.educationForms[i] = form;
    } else {
      this.educationForms.push(form);
    }
  }
  experienceEvent(form) {
    const i = this.experiences.indexOf(form.index);
    if (i !== -1) {
      this.experienceForms[i] = form;
    } else {
      this.experienceForms.push(form);
    }
  }

  next(form) {
    event.preventDefault();
    this.tabSelectionParams = ['select_tab', 'work'];
    this.isWorkDisabled = false;
  }

  complete(form) {
    this.tabSelectionParams = ['select_tab', 'complete'];
    this.isCompleteDisabled = false;
    if (form.valid && this.experienceForms !== null && this.educationForms !== null) {
      this.wf.value['files'] = this.allFiles;
      // this.wf.value['positions'] = this.job.position;
    }
  }

  getFiles(files) {
    this.allFiles = files;
  }

  submitApplication(form) {
    if (form.valid) {
      this.wf.value['files'] = this.allFiles;
      if (this.id !== 'apply') {
        this.wf.value['positions'] = this.job.position;
      }
      this.filledForm = this.zipForms([this.pf.value, this.wf.value, this.cf.value, this.educationForms, this.experienceForms]);
      // Rename key
      Object.defineProperty(this.filledForm, 'education', Object.getOwnPropertyDescriptor(this.filledForm, 3));
      delete this.filledForm[3];
      Object.defineProperty(this.filledForm, 'experience', Object.getOwnPropertyDescriptor(this.filledForm, 4));
      delete this.filledForm[4];

      Object.keys(this.filledForm).forEach(key => {
        if (this.filledForm[key] === undefined) {
          this.filledForm[key] = 'None';
        }
      });
     this.filledForm['email_to'] = this.gs.globalSettings.email;

      // console.log(JSON.stringify(this.filledForm));

      // Add to Firestore
      const appCollections = this.afs.collection('applications');
      appCollections.add(this.filledForm).then(ref => {
        this.afs.doc('/applications/' + ref.id).set({
          id: ref.id
        }, { merge: true });
        this.toastParams = ['Application Submitted. Thank you. You will be redirected to the Careers page now.', 4000];
        this.triggerToast();
        setTimeout(() => {
          this.router.navigateByUrl('/careers');
        }, 4500);
      }).catch(err => {
        this.toastParams = ['Error. Please Try Again or Contact Admin.', 4000];
        this.triggerToast();
      });

    }
  }

  zipForms(arrForms) {
    const zippedForm = {};
    arrForms.forEach((form, i) => {
      if (!Array.isArray(form)) {
        Object.keys(form).forEach(key => {
          zippedForm[key] = form[key];
        });
      } else {
        zippedForm[i] = form;
      }
    });
    return zippedForm;
  }

  triggerToast() {
    this.globalActions.emit('toast');
  }
  // const form =  {
  //   addr_1: '',
  //   addr_2: '',
  //   begin: '',
  //   dob: '',
  //   driving: '',
  //   education: [
  //     {
  //       index:  ,
  //       inst_name: '',
  //       loc: '',
  //       major: '',
  //       school_type: '',
  //       year: ''
  //     }
  //   ],
  //   email: '',
  //   experience: [
  //     {
  //       emp_name: '',
  //       from: '',
  //       index: 1,
  //       last_job_title: '',
  //       last_sal: '',
  //       phone: '',
  //       reason: '',
  //       to: ''
  //     }
  //   ],
  //   files: [''],
  //   gender: '',
  //   home_addr_1: '',
  //   home_addr_2: '',
  //   home_phone: '',
  //   mob_phone: '',
  //   more_info: '',
  //   name: '',
  //   nationality: '',
  //   noc: '',
  //   positions: '',
  //   ref_company_1: '',
  //   ref_company_2: '',
  //   ref_name_1: '',
  //   ref_name_2: '',
  //   ref_pos_1: '',
  //   ref_pos_2: '',
  //   ref_tel_1: '',
  //   ref_tel_2: '',
  //   resident: '',
  //   sal: '',
  //   sign: '',
  //   today: ''
  // };


  // private updateUserData(user) {
  //   // Sets user data to firestore on login
  //   const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
  //   const data: User = {
  //     uid: user.uid,
  //     email: user.email,
  //     roles: {
  //       admin: true
  //     }
  //   };
  //   return userRef.set(data, { merge: true });
  // }

}
