import { Component, OnInit, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MaterializeDirective, MaterializeAction } from 'angular2-materialize';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { map, take, subscribeOn } from 'rxjs/operators';
import { Project } from '../../projects-page/project';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminSettingsComponent implements OnInit {
  update_text: any;
  updates: any[];
  settings: any;
  settingsDoc: any;
  id = 'vU1Pro9ZR6VYR018oFTp';
  allImages: any[];
  selectOptions: any = [];
  globalActions = new EventEmitter<string|MaterializeAction>();
  toastParams: any;

  constructor(private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.settingsDoc = this.afs.doc(`global/${this.id}`).ref.get().then(data => {
      this.settings = data.data();
      this.updates = this.settings.updates;
    });
  }

  // addUpdates() {
  //   this.updates.push(this.update_text);
  // }

  updateSettings(form) {
    // form.value['updates'] = this.updates;
    // delete form.value['update'];
    // console.log(form.value);

    // JSON.parse(JSON.stringify(obj['project_images'], function (key, value) {
    //   return (value === undefined) ? '' : value;
    // }));

    this.afs.doc(`global/${this.id}`).set(form.value, { merge: true }).then(() => {
      console.log('Settings Updated...');
      this.toastParams = ['Settings Updated.', 4000];
      this.triggerToast();
    }).catch(err => {
      this.toastParams = ['Error. Please Try Again / Contact Admin.', 4000];
      this.triggerToast();
      // console.error(err);
    });
  }

  getFiles(files) {
    this.selectOptions = [];
    this.allImages = files;
    console.log('allImages', this.allImages);
    this.allImages.forEach((img, index) => {
      this.selectOptions.push({
        value: img,
        name: `Image ${index + 1}`
      });
    });
    console.log('selectOptions', this.selectOptions);
  }

  triggerToast() {
    this.globalActions.emit('toast');
  }

  // deleteUpdate(index) {
  //   this.updates.splice(index, 1);
  //   this.toastParams = ['Update Deleted.', 4000];
  //   this.triggerToast();
  // }

  // handleChange(event) {
  //   for (let i = 0; i < event.delta.ops.length; i++) {
  //       const operation = event.delta.ops[i];
  //       if (operation.retain && operation.attributes.link) {
  //         console.log('Inserted link:' + operation.attributes.link);
  //           const link = operation.attributes.link;
  //           if (!link.includes('http')) {
  //             event.delta.ops[i].attributes.link = 'http://' + link;
  //             console.log('Sanitized link:' + event.delta.ops[i].attributes.link);
  //             console.log(event);
  //           }
  //       }
  //   }
  // }
}
