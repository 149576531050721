import { Component, OnInit, EventEmitter } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Update } from '../../updates-page/updates';
import { MaterializeDirective, MaterializeAction } from 'angular2-materialize';

@Component({
  selector: 'app-admin-updates',
  templateUrl: './admin-updates.component.html',
  styleUrls: ['./admin-updates.component.css']
})
export class AdminUpdatesComponent implements OnInit {
  updatesCollection: AngularFirestoreCollection<Update>;
  updates: any;
  modalActions = new EventEmitter<string|MaterializeAction>();
  selectOptions: any[];
  allImages: any[];
  globalActions = new EventEmitter<string|MaterializeAction>();
  toastParams: any;
  addDisabled: any = true;
  updateImgsLen = 0;
  noUpdates: any;
  // commercial: any;
  // office: any;
  // hospitality: any;
  // res_apts: any;
  // res_indi: any;
  // res_cpds: any;

  constructor(private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.updatesCollection = this.afs.collection('updates');
    this.updates = this.updatesCollection.valueChanges().subscribe((data) => {
      this.updates = data;
      if (data.length === 0) {
        this.noUpdates = true;
      } else {
        this.noUpdates = false;
      }
    });
  }

  openModal() {
    this.modalActions.emit({action: 'modal', params: ['open']});
  }
  closeModal() {
    this.modalActions.emit({action: 'modal', params: ['close']});
  }

  getFiles(files) {
    this.allImages = files;
    this.updateImgsLen = this.allImages.length;
    // console.log(this.allImages);
  }

  addUpdate(form) {
    const i = this.allImages[form.value['main_img']];
    this.allImages.splice(form.value['main_img'], 1);
    form.value['main_img'] = i;
    form.value['images'] = this.allImages;
    // console.log(form.value);
    this.updatesCollection.add(form.value).then(ref => {
      this.afs.doc('/updates/' + ref.id).set({
        id: ref.id
      }, { merge: true });
      this.closeModal();
      this.toastParams = ['Update Added.', 4000];
      this.triggerToast();
    }).catch(err => {
      this.toastParams = ['Error. Please Try Again / Contact Admin.', 4000];
      this.triggerToast();
      console.error(err);
    });
  }

  deleteUpdate(id) {
    this.updatesCollection.doc(id).delete().then(() => {
      // console.log('Document successfully deleted!');
      this.toastParams = ['Update Deleted.', 4000];
      this.triggerToast();
    }).catch((error) => {
        console.error('Error removing document: ', error);
    });
  }

  triggerToast() {
    this.globalActions.emit('toast');
  }

}
